/* You can add global styles to this file, and also import other style files */
@import 'variables';
@import '../node_modules/bootstrap/scss/bootstrap';

@font-face {
    font-family: medievil;
    src: local("medievil"), url(./assets/fonts/MedievalSharp-Regular.ttf) format("truetype");
}


@font-face {
    font-family: sigmar;
    src: local("sigmar"), url(./assets/fonts/SigmarOne-Regular.ttf) format("truetype");
}

@font-face {
    font-family: mulish;
    src: local("mulish"), url(./assets/fonts/Mulish-VariableFont_wght.ttf) format("truetype");
}

@font-face {
    font-family: cormorantMedium;
    src: local("cormorantMedium"), url(./assets/fonts/CormorantGaramond-Medium.ttf) format("truetype");
}

@font-face {
    font-family: cormorantMediumItalic;
    src: local("cormorantMediumItalic"), url(./assets/fonts/CormorantGaramond-MediumItalic.ttf) format("truetype");
}

@font-face {
    font-family: cormorantRegular;
    src: local("cormorantRegular"), url(./assets/fonts/CormorantGaramond-Regular.ttf) format("truetype");
}

@font-face {
    font-family: openSansLight;
    src: local("openSansLight"), url(./assets/fonts/OpenSans-Light.ttf) format("truetype");
}

@font-face {
    font-family: openSansRegular;
    src: local("openSansRegular"), url(./assets/fonts/OpenSans-Regular.ttf) format("truetype");
}

@font-face {
    font-family: openSansSemiBold;
    src: local("openSansSemiBold"), url(./assets/fonts/OpenSans-SemiBold.ttf) format("truetype");
}


